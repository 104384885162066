"use client";
import { usePathname } from "next/navigation";
import { useState, useEffect, useRef } from "react";

const LoadingProgressBar = () => {
  const pathname = usePathname();
  const [loading, setLoading] = useState(false);
  const isNavigating = useRef(false); // Prevents duplicate triggers

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (isNavigating.current) return; // Prevent multiple activations

      const target = (event.target as HTMLElement).closest("a"); // Find closest `<a>`
      if (
        target &&
        target.getAttribute("href")?.startsWith("/") &&
        !target.hasAttribute("target")
      ) {
        isNavigating.current = true; // Mark navigation start
        setLoading(true);
      }
    };

    document.addEventListener("click", handleClick, { passive: true });

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
      isNavigating.current = false; // Reset navigation status
    }
  }, [pathname]);

  return (
    <>
      {loading && (
        <div
          className="fixed left-0 top-0 h-[3px] w-full"
          style={{ zIndex: 1000 }}
        >
          <div className="loading-bar h-full w-full bg-blue-600"></div>
        </div>
      )}
      <style jsx>{`
        .loading-bar {
          animation: loading 3s ease-out forwards;
        }
        @keyframes loading {
          0% {
            width: 0%;
          }
          100% {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default LoadingProgressBar;
