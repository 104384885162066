"use client";

import { ThemeProvider } from "@/src/theme/theme-provider";
import { ConfigProvider, theme as antTheme } from "antd/es";
import { SessionProvider } from "next-auth/react";
import { useTheme } from "next-themes";
import { Toaster } from "sonner";

export function Providers({ children }: { children: React.ReactNode }) {
  const { theme } = useTheme();
  return (
    <SessionProvider>
      <ThemeProvider attribute="class" defaultTheme="system">
        <ConfigProvider
          theme={{
            // 1. Use dark algorithm
            algorithm:
              theme === "dark"
                ? antTheme.darkAlgorithm
                : antTheme.defaultAlgorithm,

            // 2. Combine dark algorithm and compact algorithm
            // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
          }}
        >
          <Toaster className="dark:hidden" visibleToasts={10} richColors />
          {children}
        </ConfigProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}
